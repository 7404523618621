import { TPLSPointsShopReward } from '@open-tender/types'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import Body from 'components/Body'
import Heading from 'components/Heading'
import Text from 'components/Text'
import {
  PointsShopRewardButton,
  PointsShopRewardContent,
  PointsShopRewardCta,
  PointsShopRewardImage,
  PointsShopRewardLocked,
  PointsShopRewardLockedText,
  PointsShopRewardPurchase,
  PointsShopRewardView,
} from './PointsShopReward.styled'
import { Lock } from 'components/icons'
import { isMobile } from 'react-device-detect'

const PointsShopReward = ({
  reward,
  pointsBalance,
}: {
  reward: TPLSPointsShopReward
  pointsBalance?: number | null
}) => {
  const dispatch = useAppDispatch()
  const { name, description, image, points, is_available } = reward
  const bgStyle = image ? { backgroundImage: `url(${image}` } : undefined
  const isLocked =
    points && (pointsBalance || pointsBalance === 0)
      ? points > pointsBalance
      : false
  const disabled = isLocked || !is_available

  const onClick = () => {
    const args = { reward, pointsBalance }
    dispatch(openModal({ type: 'pointsShopReward', args }))
  }

  return (
    <PointsShopRewardButton onClick={onClick} disabled={disabled}>
      <PointsShopRewardView>
        {isLocked && (
          <PointsShopRewardLocked>
            <span>
              <Lock size={22} strokeWidth={1.5} />
            </span>
            <PointsShopRewardLockedText size={isMobile ? 'small' : 'main'}>
              Unlock at {points} points
            </PointsShopRewardLockedText>
          </PointsShopRewardLocked>
        )}
        <PointsShopRewardImage style={bgStyle} />
        <PointsShopRewardContent>
          <Heading as="p" size="main">
            {name}
          </Heading>
          {description && (
            <Body as="p" size="xSmall" style={{ marginTop: 5 }}>
              {description}
            </Body>
          )}
          <PointsShopRewardPurchase>
            <Heading as="p" size="small">
              {points} points
            </Heading>
            {!is_available && (
              <Text color="error" size="xSmall">
                Not Available
              </Text>
            )}
          </PointsShopRewardPurchase>
          {!disabled && (
            <PointsShopRewardCta color="primary" onClick={onClick} size="small">
              Claim
            </PointsShopRewardCta>
          )}
        </PointsShopRewardContent>
      </PointsShopRewardView>
    </PointsShopRewardButton>
  )
}

export default PointsShopReward
