import { LoyaltyPerk, LoyaltyProgram } from '@open-tender/types'
import { Button, LoyaltyStatus, LoyaltyThresholds } from '..'
import { useNavigate } from 'react-router-dom'
import {
  LoyaltyPointsArcProgressBar,
  LoyaltyPointsArcProgressBarView,
  LoyaltyPointsContent,
  LoyaltyPointsProgressLabelsView,
  LoyaltyPointsProgressLeftLabel,
  LoyaltyPointsProgressRightLabel,
  LoyaltyPointsRedeemButtonView,
  LoyaltyPointsView,
} from './LoyaltyPoints.styled'
import { isMobileOnly } from 'react-device-detect'
import { roundPoints } from '@open-tender/utils'

const LoyaltyPoints = ({
  program,
  perk,
  to,
  showThresholds = false,
}: {
  program: LoyaltyProgram
  perk: LoyaltyPerk
  showThresholds?: boolean
  to?: string
}) => {
  const navigate = useNavigate()
  const { spend_name } = program
  const { credit, thresholds } = perk
  const points = roundPoints(credit)
  const threshold = roundPoints(program.perk?.thresholds?.[0]?.points)
  const progress = points / threshold
  const disabled = points < threshold
  return (
    <>
      <LoyaltyPointsView>
        <LoyaltyPointsContent>
          {/* <LoyaltyHeader title={name} subtitle={description} /> */}
          <LoyaltyPointsArcProgressBarView>
            <LoyaltyPointsArcProgressBar progress={progress} barWidth={22} />
            <LoyaltyPointsProgressLabelsView>
              <LoyaltyPointsProgressLeftLabel size="main" color="tertiary">
                Start
              </LoyaltyPointsProgressLeftLabel>
              <LoyaltyPointsProgressRightLabel size="main" color="tertiary">
                {threshold}
              </LoyaltyPointsProgressRightLabel>
            </LoyaltyPointsProgressLabelsView>
          </LoyaltyPointsArcProgressBarView>
          {spend_name && (
            <LoyaltyStatus count={points} name={`${spend_name} available`} />
          )}
          {!disabled && isMobileOnly && (
            <LoyaltyPointsRedeemButtonView>
              <Button
                disabled={disabled}
                size="small"
                onClick={() => navigate('/points-shop')}
              >
                Choose Rewards
              </Button>
            </LoyaltyPointsRedeemButtonView>
          )}
        </LoyaltyPointsContent>
      </LoyaltyPointsView>

      {spend_name && thresholds && showThresholds && (
        <LoyaltyThresholds name={spend_name} thresholds={thresholds} />
      )}
    </>
  )
}

export default LoyaltyPoints
